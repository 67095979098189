import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Our Services | CourtMasters
			</title>
			<meta name={"description"} content={"CourtMasters"} />
			<meta property={"og:title"} content={"Our Services | CourtMasters"} />
			<meta property={"og:description"} content={"CourtMasters"} />
			<meta property={"og:image"} content={"https://starledo.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://starledo.com/img/Sport-tennis-icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://starledo.com/img/Sport-tennis-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://starledo.com/img/Sport-tennis-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://starledo.com/img/Sport-tennis-icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://starledo.com/img/Sport-tennis-icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://starledo.com/img/Sport-tennis-icon.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(0deg,rgba(0,0,0,.2) 0%,rgba(0,0,0,.2) 100%),--color-dark url(https://starledo.com/img/5.jpg) 50% 15%/cover"
			color="--light"
			font="--base"
		>
			<Override slot="SectionContent" sm-align-items="center" />
			<Box width="100%">
				<Text
					as="h1"
					margin="0 0 96px 0"
					text-align="center"
					letter-spacing="50px"
					text-transform="uppercase"
					font="100 132px/1.2 --fontFamily-sans"
					lg-font="200 42px/1.2 --fontFamily-sans"
					lg-letter-spacing="20px"
				>
					Our{" "}
					<br />
					Services
				</Text>
			</Box>
			<Box display="flex" margin="-16px -16px -16px -16px" flex-wrap="wrap" width="100%">
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex" font="--lead">
						<Text margin="0px">
							Main Services
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							CourtMasters Tennis Club is committed to providing you with a comprehensive tennis experience.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							We have developed a range of services designed to strengthen your love for the game, from personal training to exciting tournaments.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							Whether you want to improve your game, compete or just enjoy the sport, CourtMasters Tennis Club welcomes you.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Courts
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									- Indoor and Outdoor Courts: Choose from our range of high quality indoor and outdoor courts, available all year round for training, matches and tournaments.
									<br />
									<br />
									- Lighting and Surface Options: Our Courts feature state-of-the-art lighting for nighttime play and offer a variety of surface types including hard, clay and grass to suit your preference.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Training and education
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								- Private Coaching: Work one-on-one with our experienced coaches to hone your technique, strategy and fitness.
								<br />
								<br />
								- Group Clinics & Workshops: Join our group sessions for a fun and interactive way to improve your game and meet other community members.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Competitive and social game
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									- Leagues & Tournaments: Participate in friendly competition with our hosted leagues and tournaments designed for all skill levels.
									<br />
									<br />
									- Social Meetings & Events: Enjoy our regular social mixers, events and open game nights, perfect for making new friends and enjoying tennis in a relaxed, social environment.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Membership Benefits
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									- Exclusive Member Benefits: Members receive priority court bookings, discounts on Coach Services and store merchandise, and access to members-only events.
									<br />
									<br />
									- Flexible Membership Options: Choose from a variety of membership plans to fit your lifestyle, including family, individual and youth.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://starledo.com/img/6.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Contact Us
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					To find out about our full range of tennis services and how we can support your tennis journey. Whether you are looking to improve your game, compete or simply enjoy the sport, CourtMasters Tennis Club welcomes you.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});